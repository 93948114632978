p {
  max-width: 1025px; }

.Navigation .fade-enter {
  opacity: 0;
  z-index: 1; }

.Navigation .fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 750ms ease-in; }

.Navigation .fade-exit {
  opacity: 0;
  z-index: 1; }
